import axios from 'axios';

import {
  BASE_API_AUTH,
  BASE_API_DASHBOARD,
  BASE_API_PLAYGROUND,
  BASE_API_QUESTION_BUILDER,
  BASE_API_PAYMENTS,
  ENV,
} from '../components/Common/Constants';
import { UsersSlice } from './Reducers/loginReducer';
import { store } from '../store';
import { toast } from 'react-toastify';
import { getToken } from 'utils';

axios.defaults.baseURL = BASE_API_AUTH;

export const apiServiceInstance = axios.create({
  baseURL: BASE_API_AUTH,
  timeout: 30000,
  withCredentials: true,
});

export const apiServiceInstanceQuestionBuilder = axios.create({
  baseURL: BASE_API_QUESTION_BUILDER,
  timeout: 30000,
  withCredentials: true,
});

export const apiServiceInstanceAddQuestion = axios.create({
  baseURL: BASE_API_QUESTION_BUILDER,
  timeout: 30000,
  withCredentials: true,
});

export const apiServiceInstanceDashboard = axios.create({
  baseURL: BASE_API_DASHBOARD,
  timeout: 30000,
  withCredentials: true,
});

export const apiServiceInstanceDashboardWithWaitTime = axios.create({
  baseURL: BASE_API_DASHBOARD,
  timeout: 120000,
  withCredentials: true,
});

export const apiServiceInstancePayments = axios.create({
  baseURL: BASE_API_PAYMENTS,
  timeout: 30000,
  withCredentials: true,
});

export const apiServiceInstancePlayground = axios.create({
  baseURL: BASE_API_PLAYGROUND,
  timeout: 30000,
  withCredentials: true,
});

export const apiServiceInstancePlaygroundWithWaitTime = axios.create({
  baseURL: BASE_API_PLAYGROUND,
  timeout: 120000,
  withCredentials: true,
});

type axiosPostRequestType = {
  url: string;
  config: object;
  params: object | null;
};

export const axiosPostRequest = ({
  url,
  config,
  params,
}: axiosPostRequestType) => axios.post(url, params, config);

const pendingRequests = new Map();
const generateRequestId = (config: any) => {
  //  cancelling based only url and request method as params will be different for the same request.
  return JSON.stringify({
    method: config.method,
    url: config.url,
    // data: config.data,
    // params: config.params,
  });
};

const configModifier = (config: any) => {
  const copyConfig = Object.assign({}, config);

  if (ENV === 'development') {
    copyConfig.headers = {
      ...copyConfig.headers,
      Authorization: getToken().length !== 0 ? `Bearer ${getToken()}` : '',
    };
  }

  return copyConfig;
};

const configModifierAddQuestion = (config: any) => {
  const requestId = generateRequestId(config);

  // If request with the same identifier is pending, cancel it
  if (pendingRequests.has(requestId)) {
    pendingRequests.get(requestId).cancel('Duplicate request detected');
    pendingRequests.delete(requestId);
  }
  // Create a CancelToken for the current request
  const source = axios.CancelToken.source();
  config.cancelToken = source.token;

  // Add the request to the pending requests map
  pendingRequests.set(requestId, source);

  const copyConfig = Object.assign({}, config);
  if (ENV === 'development') {
    copyConfig.headers = {
      ...copyConfig.headers,
      Authorization: getToken().length !== 0 ? `Bearer ${getToken()}` : '',
    };
  }

  return copyConfig;
};

const removePendingRequest = (requestId: string) => {
  pendingRequests.delete(requestId);
};

apiServiceInstance.interceptors.request.use(configModifier, (error) =>
  Promise.reject(error),
);

apiServiceInstanceAddQuestion.interceptors.request.use(
  configModifierAddQuestion,
  (error) => {
    // Remove canceled requests from the pendingRequests map
    if (axios.isCancel(error)) {
      const requestId = generateRequestId(error.config);
      removePendingRequest(requestId);
    }
    return Promise.reject(error);
  },
);

apiServiceInstanceQuestionBuilder.interceptors.request.use(
  configModifier,
  (error) => Promise.reject(error),
);

apiServiceInstanceDashboard.interceptors.request.use(configModifier, (error) =>
  Promise.reject(error),
);

apiServiceInstanceDashboardWithWaitTime.interceptors.request.use(
  configModifier,
  (error) => Promise.reject(error),
);

apiServiceInstancePayments.interceptors.request.use(configModifier, (error) =>
  Promise.reject(error),
);

apiServiceInstancePlayground.interceptors.request.use(configModifier, (error) =>
  Promise.reject(error),
);

apiServiceInstancePlaygroundWithWaitTime.interceptors.request.use(
  configModifier,
  (error) => Promise.reject(error),
);

const responseInterceptor = (res: any) => res;

const responseInterceptorError = (error: any) => {
  try {
    if (error?.response?.status == 401) {
      store.dispatch(UsersSlice.actions.logout());
      // redirect to login page
      window.location.href = '/sign_in';
    }
    if (
      error?.config?.url?.includes('/question/') &&
      error?.response?.status == 403
    ) {
      return toast.error(error?.response?.data?.message);
    }
  } catch (err) {
    console.error(err);
  }
  return Promise.reject(error?.response?.data?.message);
};

apiServiceInstance.interceptors.response.use(
  responseInterceptor,
  responseInterceptorError,
);

apiServiceInstanceQuestionBuilder.interceptors.response.use(
  responseInterceptor,
  responseInterceptorError,
);

apiServiceInstanceAddQuestion.interceptors.response.use(
  responseInterceptor,
  responseInterceptorError,
);

apiServiceInstanceDashboard.interceptors.response.use(
  responseInterceptor,
  responseInterceptorError,
);

apiServiceInstanceDashboardWithWaitTime.interceptors.response.use(
  responseInterceptor,
  responseInterceptorError,
);

apiServiceInstancePayments.interceptors.response.use(
  responseInterceptor,
  responseInterceptorError,
);

apiServiceInstancePlayground.interceptors.response.use(
  responseInterceptor,
  responseInterceptorError,
);

apiServiceInstancePlaygroundWithWaitTime.interceptors.response.use(
  responseInterceptor,
  responseInterceptorError,
);

export default apiServiceInstance;
