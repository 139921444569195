import { createAsyncThunk } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { apiServiceInstanceDashboard } from '../apiUtils';
import { DashboardSlice } from '../Reducers/dashboardReducer';
import {
  formDataObjProp,
  putformDataObjProp,
  usersObjProp,
  getIndustryObjProp,
  quotaDataObjprop,
  downloadDataObjprop,
  newdownloadDataObjprop,
  saveTaxonomyObjProp,
  updatetranscriptinfoObjProp,
  getAllTranscriptionObjProp,
  aiFilterPayload,
  saveTranscriptionObj,
  approveTranscriptionObj,
  updateTaxonomyInfoObjProp,
  getThemeListType,
  getVideoListType,
  addSentimentThemeObjProp,
  activateThemeObjObjProp,
  editThemeSentimentObjProp,
  deleteThemeSentimentObjProp,
  editKeywordSentimentObjProp,
  deleteKeywordSentimentObjProp,
  AddLongFormatFileObjProp,
  deleteLongFormatFileObjProp,
  putLongFormatFileObjProp,
  updateFormAnswerObjProp,
  updateSectionStatusDataObjProp,
  createFilterObjProp,
  updateFilterObjProp,
  deleteFilterReqObjProp,
  putShowReelsTitleObjProp,
  deleteShowReelsListObjProp,
  postUploadReelsObjProp,
  postCreateShowReelsObjProp,
  getShowReelsUploadImagesObj,
  deleteShowReelsUploadObjProp,
  addReelElementsObjProp,
  getReelElementsObjProp,
  deleteReelsElementsObjProp,
  updateFormImageCaptureObjProp,
  revertFormImageCaptureObjProp,
  addFormImageCaptureObjProp,
  deleteFormImageCaptureObjProp,
  getDownloadFilesListObjProp,
  createReportPPTObjProp,
  changeElementsOrderObjProp,
  exportReelObjProp,
  deleteUserObjProp,
  mergeUserObjProp,
  deleteAllDataObjProp,
} from '../Reducers/Types';

export const getFilters = createAsyncThunk(
  'getFilters',
  async (batchId: number) => {
    const res = await apiServiceInstanceDashboard.post('analysis/getFilter/', {
      batchId,
    });
    return res;
  },
);

export const getContentAnalysisColumn = createAsyncThunk(
  'getContentAnalysisColumn',
  async (batchId: number) => {
    const res = await apiServiceInstanceDashboard.post(
      'contentAnalysis/getColumns/',
      {
        batchId,
      },
    );
    return res;
  },
);

export const getContentAnalysisData = createAsyncThunk(
  'getContentAnalysisData',
  async (batchId: number) => {
    const res = await apiServiceInstanceDashboard.post(
      'contentAnalysis/getData/',
      {
        batchId,
      },
    );
    return res;
  },
);

export const getFilterSummary = async (payload: any) => {
  try {
    const res = await apiServiceInstanceDashboard.post(
      '/contentAnalysis/getFilterSummary/',
      payload,
    );
    return res;
  } catch (err) {
    return {};
  }
};

export const getComparators = createAsyncThunk(
  'getComparators',
  async (batchId: number) => {
    const res = await apiServiceInstanceDashboard.post(
      'analysis/getCrossTabFilter/',
      {
        batchId,
      },
    );
    return res;
  },
);

export const getAnalysis = createAsyncThunk(
  'getAnalysis',
  async (reqObj: object) => {
    const res = await apiServiceInstanceDashboard.post('analysis/get/', reqObj);
    return res;
  },
);

export const getUploadList = createAsyncThunk(
  'getUploadList',
  async (reqObj: object) => {
    const res = await apiServiceInstanceDashboard.post(
      '/excel/getall/',
      reqObj,
    );
    return res;
  },
);

export const uploadExcel = async (payload: any) => {
  try {
    const res = await apiServiceInstanceDashboard.post(
      '/excel/upload/',
      payload,
    );
    return res;
  } catch (err) {
    return {};
  }
};

export const editExcel = async (payload: any) => {
  try {
    const res = await apiServiceInstanceDashboard.put(
      '/excel/upload/',
      payload,
    );
    return res;
  } catch (err) {
    return {};
  }
};

export const triggerExcel = async (payload: any) => {
  try {
    const res = await apiServiceInstanceDashboard.post(
      '/excel/trigger/',
      payload,
    );
    return res;
  } catch (err) {
    return {};
  }
};

export const getSingleExcel = createAsyncThunk(
  'getSingleExcel',
  async (formStatusObj: any) => {
    const res = await apiServiceInstanceDashboard.post(
      '/excel/getsingle/',
      formStatusObj,
    );
    return res;
  },
);

type analysisCombineTextRequestProp = {
  batchId: number;
  newText: string;
  questionId: number;
  oldTexts: Array<string>;
};

export const combineAnalysisText = createAsyncThunk(
  'combineAnalysisText',
  async ({
    batchId,
    questionId,
    newText,
    oldTexts,
  }: analysisCombineTextRequestProp) => {
    const res = await apiServiceInstanceDashboard.post('/analysis/editText/', {
      batchId,
      questionId,
      newText,
      oldTexts,
    });
    return res;
  },
);

export const getFormStatus = createAsyncThunk(
  'getFormStatus',
  async (formStatusObj: usersObjProp) => {
    const res = await apiServiceInstanceDashboard.post(
      'data/formstatus/',
      formStatusObj,
    );
    return res;
  },
);

export const getOverallStatus = createAsyncThunk(
  'getOverallStatus',
  async (overallStatusObj: any) => {
    if (overallStatusObj?.hasSection) {
      const res = await apiServiceInstanceDashboard.post(
        'data/getoverallstatus/',
        overallStatusObj.formObj,
      );
      return res;
    } else return null;
  },
);

export const getFormColumns = createAsyncThunk(
  'getFormColumns',
  async (formColumnObj: usersObjProp) => {
    const res = await apiServiceInstanceDashboard.post(
      'data/getformcolumns/',
      formColumnObj,
    );
    return res;
  },
);

export const getOverallColumns = createAsyncThunk(
  'getOverallColumns',
  async (formColumnObj: any) => {
    if (formColumnObj?.hasSection) {
      const res = await apiServiceInstanceDashboard.post(
        'data/getoverallcolumn/',
        formColumnObj?.formObj,
      );
      return res;
    } else return null;
  },
);

const splitIntoTwos = (num: number) =>
  [...Array(Math.floor(num / 2)).fill(2), num % 2 || 0].filter((n) => n);

export const getFormData = createAsyncThunk(
  'getFormData',
  async (
    formDataObj: formDataObjProp,
    { dispatch, getState, rejectWithValue },
  ) => {
    try {
      // AVT , video , image , imageCapture  , audio , file
      // AVT , text , transcript_text , video , dropdown , editableText , image , imageCapture , file , action , date , time  ,answerStatus , allAnswerd , failedQuestionList
      let state = getState();
      let columns = state?.dashboardReducer?.formColumns;
      let count =
        columns?.filter(
          (col: any) =>
            col?.displayType === 'AVT' ||
            col?.displayType === 'video' ||
            col?.displayType === 'image' ||
            col?.displayType === 'imageCapture' ||
            col?.displayType === 'audio' ||
            col?.displayType === 'file',
        )?.length ?? 0;

      const rowChunks =
        count >= 20
          ? splitIntoTwos(formDataObj?.limit)
          : [formDataObj?.limit ?? 10]; // Fetch in 3 chunks
      let totalCount = 0;
      let res;
      let index = 0;
      let offset = formDataObj?.offset;
      for (let chunkSize of rowChunks) {
        res = await apiServiceInstanceDashboard.post('/data/getform/', {
          ...formDataObj,
          limit: chunkSize,
          offset,
        });

        totalCount = res.data.totalCount; // Store total count
        offset += chunkSize;

        // ✅ Dispatch partial data to update Redux state incrementally
        dispatch(
          DashboardSlice.actions.addPartialData({
            data: res.data.data,
            totalCount,
            index,
          }),
        );
        index++;
        if (offset > totalCount) break;
      }
      return res;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error fetching data');
    }
  },
);

// export const getFormData = createAsyncThunk(
//   'getFormData',
//   async (formDataObj: formDataObjProp) => {
//     const res = await apiServiceInstanceDashboard.post(
//       '/data/getform/',
//       formDataObj,
//     );
//     return res;
//   },
// );

export const deleteUser = async (deleteUserObj: deleteUserObjProp) => {
  const res = await apiServiceInstanceDashboard.delete('/data/deleteUser/', {
    data: deleteUserObj,
  });
  return res;
};

export const mergeUser = async (mergeUserObj: mergeUserObjProp) => {
  const res = await apiServiceInstanceDashboard.put(
    '/data/mergeUser/',
    mergeUserObj,
  );
  return res;
};

export const getOverallData = createAsyncThunk(
  'getOverallData',
  async (formDataObj: any) => {
    if (formDataObj?.hasSection) {
      const res = await apiServiceInstanceDashboard.post(
        '/data/getoveralldata/',
        formDataObj?.obj,
      );
      return res;
    } else return null;
  },
);

export const putFormData = async (putformDataObj: putformDataObjProp) => {
  const res = await apiServiceInstanceDashboard.put(
    '/data/getform/',
    putformDataObj,
  );
  return res;
};

export const addFormDataAnswer = async (payload: any) => {
  const res = await apiServiceInstanceDashboard.post('/data/answer/', payload);
  return res;
};

export const getAIBuilder = createAsyncThunk(
  'getAIBuilder',
  async (batchId: number) => {
    const res = await apiServiceInstanceDashboard.post('aibuilder/get/', {
      batchId,
    });
    return res;
  },
);

export const getIndustryList = createAsyncThunk(
  'getIndustryList',
  async (getIndustryObj: getIndustryObjProp) => {
    const res = await apiServiceInstanceDashboard.post(
      'industry/getall',
      getIndustryObj,
    );
    return res;
  },
);

export const getTaxonomyList = createAsyncThunk('getTaxonomyList', async () => {
  const res = await apiServiceInstanceDashboard.get('taxonomy/');
  return res;
});

export const getQuotaData = createAsyncThunk(
  'getQuotaData',
  async (quotaDataObj: quotaDataObjprop) => {
    const res = await apiServiceInstanceDashboard.post(
      'Quota/getAll/',
      quotaDataObj,
    );
    return res;
  },
);

export const getTaxonomyById = createAsyncThunk(
  'getTaxonomyById',
  async (taxonomyId: number) => {
    const res = await apiServiceInstanceDashboard.post(`/taxonomy/getbyid`, {
      taxonomyId,
    });
    return res;
  },
);

export const getDownloadData = createAsyncThunk(
  'getDownloadData',
  async (downloadData: downloadDataObjprop) => {
    const res = await apiServiceInstanceDashboard.post(
      'download/getlist/',
      downloadData,
    );
    return res;
  },
);

export const getNewDownloadData = async (
  newdownloadData: newdownloadDataObjprop,
) => {
  const res = await apiServiceInstanceDashboard.post(
    'download/exceldownload/',
    newdownloadData,
  );
  return res;
};

export const saveTaxonomyData = async (
  saveTaxonomyObj: saveTaxonomyObjProp,
) => {
  const res = await apiServiceInstanceDashboard.post(
    'taxonomy/',
    saveTaxonomyObj,
  );
  return res;
};

export const updateTaxonomyData = async (
  saveTaxonomyObj: saveTaxonomyObjProp,
) => {
  const res = await apiServiceInstanceDashboard.put(
    'taxonomy/',
    saveTaxonomyObj,
  );
  return res;
};

export const updateTaxonomyInfo = async (
  updateTaxonomyInfoObj: updateTaxonomyInfoObjProp,
) => {
  const res = await apiServiceInstanceDashboard.put(
    'aibuilder/updatetaxnomyinfo/',
    updateTaxonomyInfoObj,
  );
  return res;
};

export const updatetranscriptinfo = async (
  updatetranscriptinfoObj: updatetranscriptinfoObjProp,
) => {
  const res = await apiServiceInstanceDashboard.put(
    'aibuilder/updatetranscriptinfo/',
    updatetranscriptinfoObj,
  );
  return res;
};

export const addtoanalysisBackend = async (addtoanalysisObj: usersObjProp) => {
  const res = await apiServiceInstanceDashboard.post(
    'aibuilder/addtoanalysis/',
    addtoanalysisObj,
  );
  return res;
};

export const getTranscriptStatus = createAsyncThunk(
  'getTranscriptStatus',
  async (getTranscriptStatusObj: usersObjProp) => {
    const res = await apiServiceInstanceDashboard.post(
      'transcript/status',
      getTranscriptStatusObj,
    );
    return res;
  },
);

export const getAllTranscription = createAsyncThunk(
  'getAllTranscription',
  async (getAllTranscriptionObj: getAllTranscriptionObjProp) => {
    const res = await apiServiceInstanceDashboard.post(
      'transcript/getall',
      getAllTranscriptionObj,
    );
    return res;
  },
);

export const saveTranscription = async (payload: saveTranscriptionObj) => {
  const res = await apiServiceInstanceDashboard.put(
    'transcript/getall',
    payload,
  );
  return res;
};

export const approveTranscription = async (
  payload: approveTranscriptionObj,
) => {
  const res = await apiServiceInstanceDashboard.put(
    'transcript/approveforsentimentanalysis/',
    payload,
  );
  return res;
};

export const getAiAnalysisFilter = async (payload: aiFilterPayload) => {
  const res = await apiServiceInstanceDashboard.post(
    'aianlysis/getfilters/',
    payload,
  );
  if (res.data.status === 'success') {
    return res.data.value;
  }
  return {};
};

export const getAiAnalysisDashboardData = async (payload: any) => {
  const res = await apiServiceInstanceDashboard.post(
    'aianlysis/getDashboard/',
    payload,
  );
  if (res.data.status === 'success') {
    return res.data.value;
  }
  return {};
};

export const getAiAnalysisSentenceData = async (payload: any) => {
  const res = await apiServiceInstanceDashboard.post(
    'aianlysis/getsentences/',
    payload,
  );
  if (res.data.status === 'success') {
    return {
      list: res.data.value,
      totalCount: res.data.totalCount,
      offset: res.data.offset,
    };
  }
  return {
    list: [],
    totalCount: 0,
    offset: 0,
  };
};

export const getAiAnalysisVideoList = async (payload: any) => {
  const res = await apiServiceInstanceDashboard.post(
    'aianlysis/getAllvideo/',
    payload,
  );
  if (res.data.status === 'success') {
    return {
      list: res.data.value,
      totalCount: res.data.totalCount,
      offset: res.data.offset,
    };
  }
  return {
    list: [],
    totalCount: 0,
    offset: 0,
  };
};

export const getAiAnalysisVideoData = async (payload: any) => {
  const res = await apiServiceInstanceDashboard.post(
    'aianlysis/getsinglevideo/',
    payload,
  );
  if (res.data.status === 'success') {
    return res.data.value;
  }
  return null;
};

export const surveyDownloadsFilter = async (payload: any) => {
  const res = await apiServiceInstanceDashboard.post(
    'download/filters/',
    payload,
  );
  if (res.data.status === 'success') {
    return res.data.value;
  }
  return null;
};

export const aiAnlysisExcelExport = async (payload: any) => {
  const res = await apiServiceInstanceDashboard.post(
    'aianlysis/dashboard_excel_export/',
    payload,
  );
  if (res.data.status === 'success') {
    return res.data.values;
  }
  return null;
};

export const getAiAnalysisSummary = async (payload: any) => {
  const res = await apiServiceInstanceDashboard.post(
    'aianlysis/getsummaryopinion/',
    payload,
  );
  if (res.data.status === 'success') {
    return res.data.value;
  }
  return null;
};

export const editAiAnalysisSummary = async (payload: any) => {
  const res = await apiServiceInstanceDashboard.put(
    'aianlysis/edit_summary/',
    payload,
  );
  if (res.data.status === 'success') {
    return res.data;
  }
  return null;
};

export const generateAiAnalysisSummary = async (payload: any) => {
  const res = await apiServiceInstanceDashboard.post(
    'aianlysis/summary_service/',
    payload,
  );
  if (res.data.status === 'success') {
    return res.data;
  }
  return null;
};

export const generateThemeSummary = async (payload: any) => {
  const res = await apiServiceInstanceDashboard.post(
    'aianlysis/trigger_theme_summary/',
    payload,
  );
  if (res.data.status === 'success') {
    return res.data;
  }
  return null;
};

export const generateAiAnalysisTheme = async (payload: any) => {
  const res = await apiServiceInstanceDashboard.post(
    'aianlysis/theme_service/',
    payload,
  );
  if (res.data.status === 'success') {
    return res.data;
  }
  return null;
};

export const generateAIAnalysis = async (payload: { batchId: number }) => {
  // generate ai analysis summary and theme for all questions
  const res = await Promise.all([
    generateAiAnalysisSummary({
      batchId: payload.batchId,
      allQuestion: true,
      questionId: -1,
    }),
    generateAiAnalysisTheme({
      batchId: payload.batchId,
      allQuestion: true,
      questionId: -1,
    }),
  ]);

  // check if both api call is success
  if (res[0] && res[1]) {
    return true;
  }

  return null;
};

export const getAiAnalysisKeyword = async (payload: any) => {
  const res = await apiServiceInstanceDashboard.post(
    'aianlysis/keywords/',
    payload,
  );
  if (res.data.status === 'success') {
    return res.data.value;
  }
  return null;
};

export const getAiAnalysisThemes = async (payload: any) => {
  const res = await apiServiceInstanceDashboard.post(
    'aianlysis/themes/',
    payload,
  );
  if (res.data.status === 'success') {
    return res.data.value;
  }
  return null;
};

export const getThemeList = createAsyncThunk(
  'getThemeList',
  async (getThemeListObj: getThemeListType) => {
    const res = await apiServiceInstanceDashboard.post(
      'theme/gelist/',
      getThemeListObj,
    );
    return res;
  },
);

export const getVideoList = createAsyncThunk(
  'getVideoList',
  async (getVideoListObj: getVideoListType) => {
    const res = await apiServiceInstanceDashboard.post(
      'theme/get/',
      getVideoListObj,
    );
    return res;
  },
);

export const addSentimentTheme = async (
  addSentimentThemeObj: addSentimentThemeObjProp,
) => {
  const res = await apiServiceInstanceDashboard.post(
    '/theme/',
    addSentimentThemeObj,
  );
  return res;
};

export const activateTheme = async (
  activateThemeObj: activateThemeObjObjProp,
) => {
  const res = await apiServiceInstanceDashboard.put(
    '/theme/activate/',
    activateThemeObj,
  );
  return res;
};

export const deleteTheme = async (
  deleteThemeObj: deleteThemeSentimentObjProp,
) => {
  const res = await apiServiceInstanceDashboard.delete('/theme/group/', {
    data: deleteThemeObj,
  });
  return res;
};

export const deleteAllData = async (deleteAllDataObj: deleteAllDataObjProp) => {
  const res = await apiServiceInstanceDashboard.delete(
    '/data/deleteAllUsers/',
    {
      data: deleteAllDataObj,
    },
  );
  return res;
};

export const deleteMediaData = async (
  deleteAllDataObj: deleteAllDataObjProp,
) => {
  const res = await apiServiceInstanceDashboard.delete(
    '/data/deleteAllMedia/',
    {
      data: deleteAllDataObj,
    },
  );
  return res;
};

export const editThemeSentiment = async (
  editThemeSentimentObj: editThemeSentimentObjProp,
) => {
  const res = await apiServiceInstanceDashboard.put(
    '/theme/group/',
    editThemeSentimentObj,
  );
  return res;
};

export const editKeywordSentiment = async (
  editKeywordSentimentObj: editKeywordSentimentObjProp,
) => {
  const res = await apiServiceInstanceDashboard.put(
    '/theme/',
    editKeywordSentimentObj,
  );
  return res;
};

export const deleteKeywordSentiment = async (
  deleteKeywordSentimentObj: deleteKeywordSentimentObjProp,
) => {
  const res = await apiServiceInstanceDashboard.delete('/theme/', {
    data: deleteKeywordSentimentObj,
  });
  return res;
};

export const AddLongFormatFile = async (
  allLongFormatFileObj: AddLongFormatFileObjProp,
) => {
  const res = await apiServiceInstanceDashboard.post(
    '/long_format/addlongvideo',
    allLongFormatFileObj,
  );
  return res;
};

export const deleteLongFormatFile = async (
  deleteLongFormatFileObj: deleteLongFormatFileObjProp,
) => {
  const res = await apiServiceInstanceDashboard.delete(
    '/long_format/addlongvideo',
    {
      data: deleteLongFormatFileObj,
    },
  );
  return res;
};

export const putLongFormatFile = async (
  putLongFormatFileObj: putLongFormatFileObjProp,
) => {
  const res = await apiServiceInstanceDashboard.put(
    '/long_format/addlongvideo',
    putLongFormatFileObj,
  );
  return res;
};

export const updateFormAnswer = async (
  updateFormAnswerObj: updateFormAnswerObjProp,
) => {
  const res = await apiServiceInstanceDashboard.post(
    '/data/updateanswer/',
    updateFormAnswerObj,
  );
  return res;
};

export const updateFormImageCapture = async (
  updateFormImageCaptureObj: updateFormImageCaptureObjProp,
) => {
  const res = await apiServiceInstanceDashboard.put(
    '/data/imageCapture/',
    updateFormImageCaptureObj,
  );
  return res;
};

export const addFormImageCapture = async (
  addFormImageCaptureObj: addFormImageCaptureObjProp,
) => {
  const res = await apiServiceInstanceDashboard.post(
    '/data/imageCapture/',
    addFormImageCaptureObj,
  );
  return res;
};

export const revertFormImageCapture = async (
  revertFormImageCaptureObj: revertFormImageCaptureObjProp,
) => {
  const res = await apiServiceInstanceDashboard.post(
    '/data/imageCaptureRevert/',
    revertFormImageCaptureObj,
  );
  return res;
};

export const deleteFormImageCapture = async (
  deleteFormImageCaptureObj: deleteFormImageCaptureObjProp,
) => {
  const res = await apiServiceInstanceDashboard.delete('/data/imageCapture/', {
    data: deleteFormImageCaptureObj,
  });
  return res;
};

export const updateSectionStatus = async (
  updateSectionStatusData: updateSectionStatusDataObjProp,
) => {
  const res = await apiServiceInstanceDashboard.post(
    '/data/updatesectionstatus/',
    updateSectionStatusData,
  );
  return res;
};

export const saveFilter = async (createFilterReqObj: createFilterObjProp) => {
  const res = await apiServiceInstanceDashboard.post(
    '/analysis/savedfilter/',
    createFilterReqObj,
  );
  return res;
};

export const updateFilter = async (updateFilterReqObj: updateFilterObjProp) => {
  const res = await apiServiceInstanceDashboard.put(
    '/analysis/savedfilter/',
    updateFilterReqObj,
  );
  return res;
};

export const deleteFilter = async (
  deleteFilterReqObj: deleteFilterReqObjProp,
) => {
  const res = await apiServiceInstanceDashboard.delete(
    '/analysis/savedfilter/',
    {
      data: deleteFilterReqObj,
    },
  );
  return res;
};

export const getSavedFilterList = createAsyncThunk(
  'getSavedFilterList',
  async (getThemeListObj: usersObjProp) => {
    const res = await apiServiceInstanceDashboard.post(
      '/analysis/getsavedfilter/',
      getThemeListObj,
    );
    return res;
  },
);

export const getShowReelsList = createAsyncThunk(
  'getShowReelsList',
  async (getShowReelsListObj: usersObjProp) => {
    const res = await apiServiceInstanceDashboard.post(
      '/reel/getproject/',
      getShowReelsListObj,
    );
    return res;
  },
);

export const getDownloadFilesList = createAsyncThunk(
  'getDownloadFilesList',
  async (getDownloadFilesListObj: getDownloadFilesListObjProp) => {
    const res = await apiServiceInstanceDashboard.post(
      '/download/filters/',
      getDownloadFilesListObj,
    );
    return res;
  },
);

export const putShowReelsTitle = async (
  putShowReelsTitleObj: putShowReelsTitleObjProp,
) => {
  const res = await apiServiceInstanceDashboard.put(
    '/reel/project/',
    putShowReelsTitleObj,
  );
  return res;
};

export const deleteShowReelsList = async (
  deleteShowReelsListObj: deleteShowReelsListObjProp,
) => {
  const res = await apiServiceInstanceDashboard.delete('/reel/project/', {
    data: deleteShowReelsListObj,
  });
  return res;
};

export const postUploadReels = async (
  postUploadReelsObj: postUploadReelsObjProp,
) => {
  const res = await apiServiceInstanceDashboard.post(
    '/reel/uploads/',
    postUploadReelsObj,
  );
  return res;
};

export const postCreateShowReels = async (
  postCreateShowReelsObj: postCreateShowReelsObjProp,
) => {
  const res = await apiServiceInstanceDashboard.post(
    '/reel/project/',
    postCreateShowReelsObj,
  );
  return res;
};

export const getShowReelsUploadImages = createAsyncThunk(
  'getShowReelsUploadImages',
  async (getShowReelsUploadImagesObj: getShowReelsUploadImagesObj) => {
    const res = await apiServiceInstanceDashboard.post(
      '/reel/getuploads/',
      getShowReelsUploadImagesObj,
    );
    return res;
  },
);

export const addReelElements = async (
  addReelElementsObj: addReelElementsObjProp,
) => {
  const res = await apiServiceInstanceDashboard.post(
    '/reel/projectinfo/',
    addReelElementsObj,
  );
  return res;
};

export const updateReelElements = async (
  addReelElementsObj: addReelElementsObjProp,
) => {
  const res = await apiServiceInstanceDashboard.put(
    '/reel/projectinfo/',
    addReelElementsObj,
  );
  return res;
};

export const deleteReelsElements = async (
  deleteReelsElementsObj: deleteReelsElementsObjProp,
) => {
  const res = await apiServiceInstanceDashboard.delete('/reel/projectinfo/', {
    data: deleteReelsElementsObj,
  });
  return res;
};

export const getReelElements = createAsyncThunk(
  'getReelElements',
  async (getReelElementsObj: getReelElementsObjProp) => {
    const res = await apiServiceInstanceDashboard.post(
      '/reel/getprojectinfo/',
      getReelElementsObj,
    );
    return res;
  },
);

export const changeElementsOrder = async (
  changeOrderObj: changeElementsOrderObjProp,
) => {
  const res = await apiServiceInstanceDashboard.post(
    '/reel/getprojectinfochangeorder/',
    changeOrderObj,
  );
  return res;
};

export const deleteShowReelsUpload = async (
  deleteShowReelsUploadObj: deleteShowReelsUploadObjProp,
) => {
  const res = await apiServiceInstanceDashboard.delete('/reel/uploads/', {
    data: deleteShowReelsUploadObj,
  });
  return res;
};
export const createReportPPT = async (
  createReportPPTObj: createReportPPTObjProp,
) => {
  const res = await apiServiceInstanceDashboard.post(
    '/download/pptdownload/',
    createReportPPTObj,
  );
  return res;
};

export const handleReelExport = async ({
  batchId,
  reelProjectId,
}: exportReelObjProp) => {
  const { data } = await apiServiceInstanceDashboard.post('/reel/download/', {
    batchId,
    reelProjectId,
  });
  return { ...data };
};

export const getReelExportList = async ({
  batchId,
  reelProjectId,
}: exportReelObjProp) => {
  try {
    const { data } = await apiServiceInstanceDashboard.post(
      '/reel/download/history/',
      {
        batchId,
        reelProjectId,
      },
    );
    return data;
  } catch (error) {
    return {};
  }
};

type getFilterComparisonProp = {
  batchId: number;
  questionId: number;
  filterList: Array<number>;
  download?: boolean;
};

export const getFilterComparisonApi = async (obj: getFilterComparisonProp) =>
  await apiServiceInstanceDashboard.post('/aianlysis/filter_compare/', obj);

export const getFilterComparison = createAsyncThunk(
  'getFilterComparison',
  async (obj: getFilterComparisonProp) => {
    const res = await getFilterComparisonApi(obj);
    if (res.data.status === 'success') {
      return res.data.values;
    }

    throw new Error('Failed to get filter comparison');
  },
);

export const getAllFilterSummary = async (payload: any) => {
  const res = await apiServiceInstanceDashboard.post(
    'analysis/getFilterSummary/',
    payload,
  );
  if (res.data.status === 'success') {
    return res.data.values;
  }
  return null;
};
