import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  apiServiceInstanceDashboard,
  apiServiceInstanceDashboardWithWaitTime,
  apiServiceInstancePlayground,
  apiServiceInstancePlaygroundWithWaitTime,
} from '../apiUtils';
import {
  activateThemeLongFormatType,
  addNoteObjProp,
  addResponseObjProp,
  deleteLongFormatThemeVerbatimObjType,
  deleteNoteObjPropType,
  deleteThemeLongFormatType,
  editLongFormatThemeSentimentType,
  editNoteObjTypeProp,
  editSpeakerNameObjProp,
  getPlaygroundChatObjProp,
  getPlaygroundResponseByIdObjProp,
  getPlaygroundResponseObjProp,
  getThemeListType,
  getVideoLevelThemeListLongFormatType,
  hideSpeakerObjProp,
  PanelObjProp,
  postEditSpeakerObjType,
  postEditTranscriptObjType,
  postLongFormatThemeObjType,
  postLongFormatThemeVerbatimObjType,
  postRevertTranscriptObjType,
} from '../Reducers/Types';
import { RootState } from '..';
import { LongFormatActions } from '../Reducers/longFormatReducer';
import { toast } from 'react-toastify';

export type treeDataPayloadType = {
  questionId: number;
  batchId: number;
  theme: string;
};

export type videoDataPayloadType = {
  questionId: number;
  batchId: number;
  videoId: number;
};
export const getThemeListLongFormat = createAsyncThunk(
  'getThemeListLongFormat',
  async (getThemeListLongFormat: getThemeListType) => {
    const res = await apiServiceInstanceDashboard.post(
      '/long_format/getPrimaryThemes/',
      getThemeListLongFormat,
    );
    return res;
  },
);

export const getVideoLevelThemeListLongFormat = createAsyncThunk(
  'getVideoLevelThemeListLongFormat',
  async (getThemeListLongFormat: getVideoLevelThemeListLongFormatType) => {
    const res = await apiServiceInstanceDashboard.post(
      '/themeLongForamt/gelist/',
      getThemeListLongFormat,
    );
    return res;
  },
);

export const activateLongFormatTheme = async (
  activateThemeObj: activateThemeLongFormatType,
) => {
  const res = await apiServiceInstanceDashboard.put(
    '/themeLongForamt/activate/',
    activateThemeObj,
  );
  return res;
};

export const deleteLongFormatTheme = async (
  deleteThemeObj: deleteThemeLongFormatType,
) => {
  const res = await apiServiceInstanceDashboard.delete(
    '/themeLongForamt/gelist/',
    {
      data: deleteThemeObj,
    },
  );
  return res;
};

export const postLongFormatTheme = async (
  postLongFormatThemeObj: postLongFormatThemeObjType,
) => {
  const res = await apiServiceInstanceDashboard.post(
    '/themeLongForamt/',
    postLongFormatThemeObj,
  );
  return res;
};

export const postLongFormatThemeVerbatim = async (
  postLongFormatThemeVerbatimObj: postLongFormatThemeVerbatimObjType,
) => {
  const res = await apiServiceInstanceDashboard.post(
    '/ThemeVerbatimLongFormat/verbatim/',
    postLongFormatThemeVerbatimObj,
  );
  return res;
};

export const postEditSpeaker = async (
  postEditSpeakerObj: postEditSpeakerObjType,
) => {
  const res = await apiServiceInstanceDashboard.post(
    '/long_format/changeSpeaker/',
    postEditSpeakerObj,
  );
  return res;
};

export const postEditTranscript = async (
  postEditTranscriptObj: postEditTranscriptObjType,
) => {
  const res = await apiServiceInstanceDashboard.put(
    '/long_format/getTranscript/',
    postEditTranscriptObj,
  );
  return res;
};

export const postRevertTranscript = async (
  postRevertTranscriptObj: postRevertTranscriptObjType,
) => {
  const res = await apiServiceInstanceDashboard.post(
    '/long_format/revertTranscript/',
    postRevertTranscriptObj,
  );
  return res;
};

export const deleteLongFormatThemeVerbatim = async (
  postLongFormatThemeVerbatimObj: deleteLongFormatThemeVerbatimObjType,
) => {
  const res = await apiServiceInstanceDashboard.delete(
    '/ThemeVerbatimLongFormat/verbatim/',
    {
      data: postLongFormatThemeVerbatimObj,
    },
  );
  return res;
};

export const editLongFormatThemeSentiment = async (
  editThemeSentimentObj: editLongFormatThemeSentimentType,
) => {
  const res = await apiServiceInstanceDashboard.put(
    '/themeLongForamt/renameGroup/',
    editThemeSentimentObj,
  );
  return res;
};

export const getTreeData = async (payload: treeDataPayloadType) => {
  const res = await apiServiceInstanceDashboard.post(
    '/long_format/getGraphCluster/',
    payload,
  );
  return res.data;
};

export const getLongFormatUploadedList = async (payload: getThemeListType) => {
  const res = await apiServiceInstanceDashboard.post(
    '/long_format/getUploadedList/',
    payload,
  );
  return res.data.value;
};

export const getLongformatAnalysisVideoList = async (payload: any) => {
  const res = await apiServiceInstanceDashboard.post(
    '/long_format/getAllVideos/',
    payload,
  );
  if (res.data.status === 'success') {
    return {
      list: res.data.value,
      totalCount: res.data.totalCount,
      offset: res.data.offset,
      clusteringPending: res.data.clusteringPending,
      haveExistingCluster: res.data.haveExistingCluster,
    };
  }
  return {
    list: [],
    totalCount: 0,
    offset: 0,
    clusteringPending: false,
    haveExistingCluster: false,
  };
};

export const getLongformatVideoData = async (payload: any) => {
  const res = await apiServiceInstanceDashboard.post(
    '/long_format/getSingleVideo/',
    payload,
  );
  if (res.data.status === 'success') {
    return res.data.value;
  }
  return null;
};

export const downloadLongFormatTranscript = async (payload: any) => {
  const res = await apiServiceInstanceDashboard.post(
    '/long_format/downlaodTranscript/',
    payload,
  );
  if (res.data.status === 'success') {
    return res.data.url;
  }
  return null;
};

export const getLongformatWordList = async (payload: any) => {
  const res = await apiServiceInstanceDashboard.post(
    '/long_format/getWordTimeStamp/',
    payload,
  );
  if (res.data.status === 'success') {
    return res.data;
  }
  return null;
};

export const hideSpeaker = async (hideSpeakerObj: hideSpeakerObjProp) => {
  const res = await apiServiceInstanceDashboard.post(
    '/long_format/hideSpeakerAnalysis/',
    hideSpeakerObj,
  );
  return res;
};

export const editSpeakerName = async (
  editSpeakerNameObj: editSpeakerNameObjProp,
) => {
  const res = await apiServiceInstanceDashboard.post(
    '/long_format/renameSpeaker/',
    editSpeakerNameObj,
  );
  return res;
};

export const addNote = async (addNoteObj: addNoteObjProp) => {
  const res = await apiServiceInstanceDashboard.post(
    '/long_format/notes/',
    addNoteObj,
  );
  return res;
};

export const editNotes = async (editNoteObj: editNoteObjTypeProp) => {
  const res = await apiServiceInstanceDashboard.put(
    '/long_format/notes/',
    editNoteObj,
  );
  return res;
};

export const deleteNote = async (deleteNoteObj: deleteNoteObjPropType) => {
  const res = await apiServiceInstanceDashboard.delete('/long_format/notes/', {
    data: deleteNoteObj,
  });
  return res;
};

export const getLongformatTranscript = async (payload: any) => {
  const res = await apiServiceInstanceDashboard.post(
    '/long_format/getTranscript/',
    payload,
  );
  if (res.status === 200) {
    return res.data;
  }
  return null;
};

type clusterServiceType = {
  batchId: number;
  questionId: number;
  isRegenerate: boolean;
};

export const clusterService = createAsyncThunk(
  'invokeClusterService',
  async (payload: clusterServiceType) => {
    const res = await apiServiceInstanceDashboard.post(
      '/long_format/cluster_service/',
      payload,
    );
    return res;
  },
);

export const addResponse = async (addResponseObj: addResponseObjProp) => {
  const res = await apiServiceInstanceDashboard.post(
    '/long_format/addResponse/',
    addResponseObj,
  );
  return res;
};

export const downloadClusterAnalysis = async (downloadClusterAnalysisObj: {
  batchId: number;
}) => {
  const res = await apiServiceInstanceDashboard.post(
    '/contentAnalysis/download/',
    downloadClusterAnalysisObj,
  );
  return res;
};

export const editResponse = async (editResponseObj: addResponseObjProp) => {
  const res = await apiServiceInstanceDashboard.put(
    '/long_format/addResponse/',
    editResponseObj,
  );
  return res;
};

export const getPlaygroundChatList = async (
  getPlaygroundChatListObj: PanelObjProp,
) => {
  const res = await apiServiceInstancePlayground.post(
    '/playground/getchatlist/',
    getPlaygroundChatListObj,
  );
  return res;
};

export const getPlaygroundChat = async (
  getPlaygroundChatObj: getPlaygroundChatObjProp,
) => {
  const res = await apiServiceInstancePlayground.post(
    '/playground/getchatresponse/',
    getPlaygroundChatObj,
  );
  return res;
};

export const getPlaygroundResponse = async (
  getPlaygroundResponseObj: getPlaygroundResponseObjProp,
) => {
  const res = await apiServiceInstancePlaygroundWithWaitTime.post(
    '/playground/getlongformat/',
    getPlaygroundResponseObj,
  );
  return res;
};

export const getCreateChatId = async (getPlaygroundResponseUsersObj: any) => {
  try {
    const res = await apiServiceInstancePlaygroundWithWaitTime.post(
      '/playground/createchatid/',
      getPlaygroundResponseUsersObj,
    );
    return res?.data ?? {};
  } catch (err) {
    console.error(err);
    return {};
  }
};
export const getPlaygroundNewResponse = async (
  getPlaygroundResponseUsersObj: any,
) => {
  let retries = 10;

  while (retries > 0) {
    try {
      const res = await apiServiceInstancePlaygroundWithWaitTime.post(
        '/playground/getQueryResponse/',
        getPlaygroundResponseUsersObj,
      );

      // Check if the response data is the "wait" object
      if (res.data && res.data.status === 'wait') {
        toast.info('We are still processing your data. Please wait patiently.');
        retries--;
        // Optionally, delay before retrying (e.g., 1 second)
        await new Promise((resolve) => setTimeout(resolve, 1000 * 15));
        continue;
      }

      // If status is 'succusses', return the data
      if (res.data && res.data.status === 'succusses') {
        return res.data.value;
      }

      // In other cases, you might choose to return the data or handle it differently
      return res.data.value ?? [];
    } catch (err) {
      console.error('Error occurred, retrying...', err);
      retries--;
      // Optionally, delay before retrying on error
      await new Promise((resolve) => setTimeout(resolve, 1000 * 15));
    }
  }

  // If we've exhausted retries without a successful response, throw an error
  console.error('Failed to get a successful response after retries.');

  return {
    status: 'failed',
  };
};

export const getPlaygroundResponseById = async (
  getPlaygroundResponseByIdObj: getPlaygroundResponseByIdObjProp,
) => {
  const res = await apiServiceInstancePlaygroundWithWaitTime.post(
    '/playground/getlongformatResponse/',
    getPlaygroundResponseByIdObj,
  );
  return res.data.response ?? [];
};

export const getShortFormPlaygroundNewResponse = async (
  getPlaygroundResponseByIdObj: getPlaygroundResponseObjProp,
) => {
  const res = await apiServiceInstancePlaygroundWithWaitTime.post(
    '/playground/getShortFormQueryResponse/',
    getPlaygroundResponseByIdObj,
  );
  return res.data?.value ?? [];
};

export const getShortPlaygroundChat = async (
  getPlaygroundChatObj: getPlaygroundChatObjProp,
) => {
  const res = await apiServiceInstancePlayground.post(
    '/playground/getShortChatResponse/',
    getPlaygroundChatObj,
  );
  return res.data?.value ?? [];
};

export const addMultipleQuery = async (getPlaygroundResponseByIdObj: {
  batchId: number;
  queryArray: Array<string>;
  addToContentAnalysis: boolean;
}) => {
  const res = await apiServiceInstancePlaygroundWithWaitTime.post(
    '/playground/addMultipleQuery/',
    getPlaygroundResponseByIdObj,
  );
  return res;
};

export const getPlaygroundResponseSummary = async (
  getPlaygroundResponseSummaryObj: any,
) => {
  let retries = 15;

  while (retries > 0) {
    try {
      const res = await apiServiceInstancePlaygroundWithWaitTime.post(
        '/playground/getlongformatSummary/',
        getPlaygroundResponseSummaryObj,
      );

      // Check if the response data is the "wait" object
      if (
        res.data &&
        res.data.summary === '' &&
        Object.keys(res.data.summaryProof).length === 0 &&
        res.data.status === 'wait'
      ) {
        toast.info('We are still processing your data. Please wait patiently.');
        retries--;
        // Optionally, delay before retrying (e.g., 1 second)
        await new Promise((resolve) => setTimeout(resolve, 1000 * 15));
        continue;
      }

      // If status is 'succusses', return the data
      if (res.data && res.data.status === 'succusses') {
        return res.data;
      }

      // In other cases, you might choose to return the data or handle it differently
      return res.data ?? [];
    } catch (err) {
      console.error('Error occurred, retrying...', err);
      retries--;
      // Optionally, delay before retrying on error
      await new Promise((resolve) => setTimeout(resolve, 1000 * 15));
    }
  }

  // If we've exhausted retries without a successful response, throw an error
  console.error('Failed to get a successful response after retries.');

  return {
    status: 'failed',
    summary: -1,
    summaryProof: {},
  };
};

export const deleteLongFormatPlaygroundChat = async (deleteThemeObj: any) => {
  const res = await apiServiceInstancePlayground.delete(
    '/playground/getchatresponse/',
    {
      data: deleteThemeObj,
    },
  );
  return res;
};

export const postPinChat = async (postPinChatObj: any) => {
  const res = await apiServiceInstancePlaygroundWithWaitTime.post(
    '/playground/pinChat/',
    postPinChatObj,
  );
  return res.data ?? [];
};

export const toggleContentAnalysis = async (toggleContentAnalysisObj: any) => {
  const res = await apiServiceInstancePlaygroundWithWaitTime.post(
    '/playground/addToContentAnalysis/',
    toggleContentAnalysisObj,
  );
  return res.data ?? [];
};

export const getPlaygroundChatProof = async (
  getPlaygroundChatProofObj: any,
) => {
  const res = await apiServiceInstancePlaygroundWithWaitTime.post(
    '/playground/getchatproof/',
    getPlaygroundChatProofObj,
  );
  return res.data ?? [];
};

export const reRunQuery = async (reRunQueryObj: any) => {
  const res = await apiServiceInstancePlaygroundWithWaitTime.post(
    '/playground/reRunLongFormatQuery/',
    reRunQueryObj,
  );
  return res.data?.message ?? [];
};

export const retriggerVerbatim = async (reRunQueryObj: any) => {
  const res = await apiServiceInstancePlaygroundWithWaitTime.post(
    '/playground/trigger_retry_verbatim/',
    reRunQueryObj,
  );
  return res.data?.message ?? [];
};

type searchForAllVideoType = {
  batchId: number;
  searchString: string;
  searchType: 'exact' | 'fuzzy';
  videoId?: number;
};

export const getSearchVideo = createAsyncThunk(
  'getSearchResults',
  async (searchObj: searchForAllVideoType & { videoId: number }) => {
    const res = await apiServiceInstanceDashboard.post(
      '/long_format/search/',
      searchObj,
    );
    return res.data ?? [];
  },
);

export const searchForAllVideo = createAsyncThunk(
  'searchForAllVideo',
  async (obj: searchForAllVideoType, { dispatch, getState }) => {
    const state = getState() as RootState;

    dispatch(
      LongFormatActions.updateSearchString({
        searchString: obj.searchString,
        searchType: obj.searchType,
      }),
    );

    if (!!obj.videoId) {
      await dispatch(getSearchVideo({ ...obj, videoId: obj.videoId }));
      return;
    }

    const vidoeIdList = state.longFormatReducer.videoList.map(
      (video) => video.id,
    );

    await Promise.all(
      vidoeIdList.map((videoId) => {
        return dispatch(getSearchVideo({ ...obj, videoId }));
      }),
    );
  },
);

export type ReplaceWordType = {
  batchId: number;
  videoId: number;
  modifications: Array<{
    sentenceId: number;
    startIndex: number;
    endIndex: number;
    original: string;
    modified: string;
  }>;
};

export const replaceWords = createAsyncThunk(
  'replaceWords',
  async (replaceWordObj: Array<ReplaceWordType>) => {
    const completedSentenceId = await Promise.all(
      replaceWordObj.map(async (replaceWord) => {
        return apiServiceInstanceDashboard
          .post('/long_format/replaceWords/', replaceWord)
          .then((res) =>
            replaceWord.modifications.map((mod) => mod.sentenceId),
          );
      }),
    );

    // remove the updated words from the search result
    return completedSentenceId.flat();
  },
);
